.homepage {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.pageLayoutComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.websiteSchema:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.headerHomepageBase:global(.__wab_instance) {
  max-width: 100%;
}
.headerHomepageBaseglobal__032720251400041620250600_override:global(
    .__wab_instance
  ) {
  display: none;
}
.headerHpBookdayOuter:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.headerHpBookdayOuterglobal__032720251400041620250600_override:global(
    .__wab_instance
  ) {
  display: flex;
}
.heroSliderOfficial:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.herocardHomepage:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
.holi2025:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.marketingListsComponent:global(.__wab_instance) {
  position: relative;
}
.gridWithGradientButton:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.featureDetailHome__myNbm:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.aRectangularGridComposedOfMockupsAndDesignsFreePlaceitsTemplatesInSoftNeutralTonesFeaturingAHighlightedDesignWithAColorFreeLabelAtTheTop:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__u7Dh:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link___5Qtfq {
  position: relative;
  background: #c1a06f;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome__eHXb3:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.anOpenLaptopDisplayingPlaceitsEditorWithApparelMockupsSurroundingItHighlightingTheEaseOfEditingMockups:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo___1JNKa:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link___3Luts {
  position: relative;
  background: #f19a98;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title2 {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome___8QOps:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.aVideoGameControllerFilledWithPurpleAndNeonGamingDesignTemplatesShowcasingPlaceitByEnvatosCustomizationOptions:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__ae7Pu:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link___2Oc29 {
  position: relative;
  background: #8e59cf;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title3 {
  line-height: var(--token-wmteqkiSA);
}
.featureDetailHome__j2Su9:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.fiveCustomizableLogosInVariousColorsAndStylesUsingThePlaceitByEnvatoLogoMakerDemonstratingEasyFontTextColorAndGraphicAdjustments:global(
    .__wab_instance
  ) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.htmlVideo__gin0G:global(.__wab_instance) {
  width: 640px;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.link___10CoL {
  position: relative;
  background: #78d0cb;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-D1V5iINMo);
  border-radius: 6px;
  padding: 12px 24px;
  border-width: 0px;
  border-style: solid;
}
.title4 {
  line-height: var(--token-wmteqkiSA);
}
._017CtaBlock:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 40px;
}
.seoTag:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
