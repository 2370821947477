.root {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  justify-self: flex-start;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .root {
    overflow: hidden;
  }
}
@media (min-width: 900px) {
  .root {
    height: auto;
  }
}
.mainContainer2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  background: url("/plasmic/placeit/images/backgroundJpg4.jpg") top 50% left 50% / cover repeat;
  margin-right: 0px;
  min-width: 0;
  border-bottom: 1px solid var(--token-wrFJtVAO5);
}
@media (min-width: 600px) {
  .mainContainer2 {
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .mainContainer2 {
    height: 240px;
    background: url("/plasmic/placeit_home_extras/images/backgroundJpg.jpg") top 50% left 50% / cover repeat;
  }
}
@media (min-width: 1024px) {
  .mainContainer2 {
    height: 375px;
    padding: 0px;
  }
}
@media (min-width: 1536px) {
  .mainContainer2 {
    height: 375px;
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .mainContainer2 {
    padding-left: 0px;
    max-width: 100%;
    height: 375px;
  }
}
.texts2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 60%;
  height: 100%;
  max-width: 100%;
  background: #f7f0de;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 0;
  padding: var(--token-TCakqYrfy) 16px;
}
.texts2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.texts2 > :global(.__wab_flex-container) > *,
.texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.texts2 > :global(.__wab_flex-container) > picture > img,
.texts2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .texts2 {
    width: 50%;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 600px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 {
    width: 50%;
    background: #f7f0de;
    height: 100%;
    min-height: 0;
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-height: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 900px) {
  .texts2 > :global(.__wab_flex-container) > *,
  .texts2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .texts2 > :global(.__wab_flex-container) > picture > img,
  .texts2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 1024px) {
  .texts2 {
    background: #f7f0de;
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .texts2 {
    width: 55%;
  }
}
@media (min-width: 1536px) {
  .texts2 {
    width: 55%;
    padding: 0px 0px 0px var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1536px) {
  .texts2 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1900px) {
  .texts2 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 1900px) {
  .texts2 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.texts2global_ctaLevel_annual {
  background: #f7f0de;
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 6px);
  height: calc(100% + 6px);
}
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.texts2global_ctaLevel_annual > :global(.__wab_flex-container) > picture > img,
.texts2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 6px;
}
.text__kxGpl {
  position: relative;
  width: 165px;
  height: auto;
  max-width: 100%;
  font-size: 14px;
  text-align: center;
  color: #535353;
  border-radius: 20px;
  border: 1px solid #000000;
}
@media (min-width: 900px) {
  .text__kxGpl {
    font-size: 16px;
    width: 189px;
    margin-right: 0px;
  }
}
.text__b7ZSy {
  color: #191919;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: var(--token-wmteqkiSA);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  letter-spacing: 0px;
  min-width: 0;
}
@media (min-width: 600px) {
  .text__b7ZSy {
    font-size: 24px;
    text-align: center;
  }
}
@media (min-width: 900px) {
  .text__b7ZSy {
    color: #191919;
    font-size: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1024px) {
  .text__b7ZSy {
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: var(--token-wmteqkiSA);
    color: #191919;
  }
}
@media (min-width: 1280px) {
  .text__b7ZSy {
    max-width: 640px;
    font-size: 40px;
  }
}
@media (min-width: 1536px) {
  .text__b7ZSy {
    font-size: 40px;
  }
}
.textglobal_ctaLevel_annual__b7ZSyOlFmy {
  color: #191919;
}
.textglobal_ctaLevel_pastdue__b7ZSyXogrC {
  color: #191919;
}
.textglobal_ctaLevel_pastdue__veVzkXogrC {
  display: none;
}
.textglobal_ctaLevel_annual___6C0YDolFmy {
  display: none;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 14px;
  color: var(--token-D1V5iINMo);
  font-weight: 400;
  text-align: left;
  letter-spacing: normal;
  user-select: none;
  padding-left: 0px;
  line-height: 17.6px;
  font-family: "Source Sans Pro", sans-serif;
  min-width: 0;
}
@media (min-width: 600px) {
  .h1 {
    line-height: var(--token-wmteqkiSA);
    text-align: center;
    font-size: 16px;
  }
}
@media (min-width: 900px) {
  .h1 {
    color: #191919;
  }
}
@media (min-width: 1024px) {
  .h1 {
    padding: 0px 0px 4px;
  }
}
@media (min-width: 1280px) {
  .h1 {
    padding-bottom: 4px;
    font-size: 18px;
  }
}
@media (min-width: 1536px) {
  .h1 {
    padding-bottom: 4px;
    font-size: 24px;
  }
}
.h1global_ctaLevel_visitor {
  font-size: 14px;
}
.buttons2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 0px;
}
.buttons2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons2 > :global(.__wab_flex-container) > *,
.buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttons2 > :global(.__wab_flex-container) > picture > img,
.buttons2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
@media (min-width: 600px) {
  .buttons2 > :global(.__wab_flex-container) {
    align-items: center;
    justify-content: center;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 8px);
    height: calc(100% + 8px);
  }
}
@media (min-width: 600px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 8px;
  }
}
@media (min-width: 900px) {
  .buttons2 {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    width: auto;
    height: auto;
  }
}
@media (min-width: 900px) {
  .buttons2 > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2 {
    padding-top: 0px;
  }
}
@media (min-width: 1024px) {
  .buttons2 > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
@media (min-width: 1280px) {
  .buttons2 > :global(.__wab_flex-container) {
    margin-left: calc(0px - 8px);
    width: calc(100% + 8px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1280px) {
  .buttons2 > :global(.__wab_flex-container) > *,
  .buttons2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .buttons2 > :global(.__wab_flex-container) > picture > img,
  .buttons2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 8px;
    margin-top: 0px;
  }
}
.buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.buttons2global_ctaLevel_monthly > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 8px;
}
.buttons2global_ctaLevel_annual > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: center;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.buttons2global_ctaLevel_annual > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_annual
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.buttons2global_ctaLevel_pastdue > :global(.__wab_flex-container) > *,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > picture
  > img,
.buttons2global_ctaLevel_pastdue
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 4px;
}
.link__a1WYe {
  display: flex;
}
.linkglobal_ctaLevel_annual__a1WYeolFmy {
  display: none;
}
.clickEventReporter___49Y4P:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.clickEventReporterglobal_ctaLevel_annual___49Y4PolFmy:global(.__wab_instance) {
  display: none;
}
.subscribeNow {
  display: flex;
}
.buttonMegaUltaCta__rzTyX:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
}
.goUnlimitedBtn2 {
  position: relative;
  margin-bottom: 8px;
  color: #ffffff;
  display: none;
}
@media (min-width: 600px) {
  .goUnlimitedBtn2 {
    display: none;
  }
}
@media (min-width: 1280px) {
  .goUnlimitedBtn2 {
    margin-bottom: 0px;
  }
}
.goUnlimitedBtn2global_ctaLevel_monthly {
  color: #000000;
  font-size: var(--token-jFlfsKM8_wXg);
  display: none;
}
.goUnlimitedBtn2global_ctaLevel_annual {
  display: none;
}
.goUnlimitedBtn2global_ctaLevel_pastdue {
  display: flex;
}
.link__qXthB {
  color: #3dbe00;
  background: #ffffff;
  display: none;
}
@media (min-width: 900px) {
  .link__qXthB {
    color: #ffffff;
  }
}
.linkglobal_ctaLevel_monthly__qXthBu1Vk1 {
  color: #ffffff;
}
.linkglobal_ctaLevel_monthly__qXthBu1Vk1 > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.linkglobal_ctaLevel_monthly__qXthBu1Vk1 > :global(.__wab_flex-container) > *,
.linkglobal_ctaLevel_monthly__qXthBu1Vk1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.linkglobal_ctaLevel_monthly__qXthBu1Vk1
  > :global(.__wab_flex-container)
  > picture
  > img,
.linkglobal_ctaLevel_monthly__qXthBu1Vk1
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.linkglobal_ctaLevel_annual__qXthBolFmy {
  display: flex;
}
.linkglobal__021020250600030920250600_override__qXthBuZp9N {
  padding-left: 0px;
  color: #ffffff;
  display: none;
}
.btn2Home:global(.__wab_instance) {
  transition-property: all;
  transition-duration: 1s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 900px) {
  .btn2Home:global(.__wab_instance) {
    width: auto;
    height: auto;
  }
}
.btn2Homeglobal_ctaLevel_annual:global(.__wab_instance) {
  display: block;
}
.btn2Homeglobal_ctaLevel_regular:global(.__wab_instance) {
  height: 100%;
}
.btn2Homeglobal__021020250600030920250600_override:global(.__wab_instance) {
  display: none;
}
.clickEventReporter__t02J:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
.clickEventReporterglobal_ctaLevel_annual__t02JOlFmy:global(.__wab_instance) {
  display: flex;
}
.discoverCollectionBtn2 {
  color: #ffffff;
  display: none;
}
@media (min-width: 600px) {
  .discoverCollectionBtn2 {
    display: none;
  }
}
.discoverCollectionBtn2global_ctaLevel_monthly {
  color: #ffffff;
}
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container) {
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > *,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > picture
  > img,
.discoverCollectionBtn2global_ctaLevel_monthly
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
  margin-top: 0px;
}
.discoverCollectionBtn2global_ctaLevel_annual {
  display: flex;
}
.discoverCollectionBtn2global_ctaLevel_regular {
  color: var(--token-jgohepLVeKvh);
}
.buttonMegaUltaCta___9Mwrv:global(.__wab_instance):global(.__wab_instance) {
  max-width: 100%;
}
.link__g2PHg {
  margin-top: -8px;
  display: none;
}
@media (min-width: 900px) {
  .link__g2PHg {
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .link__g2PHg {
    margin-top: -8px;
  }
}
@media (min-width: 1280px) {
  .link__g2PHg {
    margin-top: 0px;
  }
}
.linkglobal_ctaLevel_annual__g2PHgolFmy {
  display: none;
}
.imgs2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
  max-width: 100%;
  background: url("/plasmic/placeit/images/backgroundJpg4.jpg") top 50% left 50% / cover repeat;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .imgs2 {
    width: 50%;
    align-items: flex-end;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .imgs2 {
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .imgs2 {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .imgs2 {
    width: 45%;
  }
}
@media (min-width: 1536px) {
  .imgs2 {
    width: 45%;
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1900px) {
  .imgs2 {
    width: 100%;
    min-width: 0;
  }
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  align-self: auto;
  object-position: right center;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: right center;
}
@media (min-width: 600px) {
  .img {
    object-position: right center;
    object-fit: cover;
  }
}
@media (min-width: 600px) {
  .img > picture > img {
    object-fit: cover;
    object-position: right center;
  }
}
@media (min-width: 900px) {
  .img {
    align-self: auto;
    object-fit: cover;
    object-position: left top;
  }
}
@media (min-width: 900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: left top;
  }
}
@media (min-width: 1024px) {
  .img {
    object-fit: cover;
  }
}
@media (min-width: 1024px) {
  .img > picture > img {
    object-fit: cover;
  }
}
@media (min-width: 1280px) {
  .img {
    object-position: right top;
  }
}
@media (min-width: 1280px) {
  .img > picture > img {
    object-position: right top;
  }
}
@media (min-width: 1536px) {
  .img {
    object-position: right top;
  }
}
@media (min-width: 1536px) {
  .img > picture > img {
    object-position: right top;
  }
}
@media (min-width: 1900px) {
  .img {
    object-fit: cover;
    object-position: center top;
  }
}
@media (min-width: 1900px) {
  .img > picture > img {
    object-fit: cover;
    object-position: center top;
  }
}
